// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

export default {
  bindAll() {
    const institutions = firebase.database().ref('organizations')
    return institutions
  },
  bindAllByNetworkId(networkId) {
    const institutions = firebase.database().ref('organizations')
      .orderByChild('network/id')
      .equalTo(networkId)
    return institutions
  },
  bindAllByAdvisorId(advisorId) {
    const institutions = firebase.database().ref('organizations')
      .orderByChild('advisor/id')
      .equalTo(advisorId)
    return institutions
  },
  bindChild(id) {
    const organization = firebase.database().ref('organizations').child(id)
    return organization
  },
  async getAll() {
    const snapshot = await firebase.database().ref('organizations').once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => resultArray.push(resultObj[key]))
      return resultArray
    }
    return []
  },
  async getLogoURL(organizationId, filename) {
    let url = ''
    try {
      url = await firebase.storage().ref().child(`organizations/${organizationId}/logo/${filename}`).getDownloadURL()
    } catch (err) {
      url = ''
    }

    return url
  },
  async get(id) {
    const snapshot = await firebase.database().ref(`organizations/${id}`).once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      return resultObj
    }
    return null
  },
  async getAllByContactId(contactId) {
    const snapshot = await firebase.database().ref('organizations')
      .orderByChild('contact/id')
      .equalTo(contactId)
      .once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => {
        const item = resultObj[key]
        item.organizationId = key
        resultArray.push(item)
      })
      return resultArray
    }
    return []
  },
  async getAllByAdvisorId(advisorId) {
    const snapshot = await firebase.database().ref('organizations')
      .orderByChild('advisor/id')
      .equalTo(advisorId)
      .once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => {
        const item = resultObj[key]
        item.organizationId = key
        resultArray.push(item)
      })
      return resultArray
    }
    return []
  },
  async getAllByNetworkId(networkId) {
    const snapshot = await firebase.database().ref('organizations')
      .orderByChild('network/id')
      .equalTo(networkId)
      .once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => {
        const item = resultObj[key]
        item.organizationId = key
        resultArray.push(item)
      })
      return resultArray
    }
    return []
  },
  create(data) {
    const ref = firebase.database().ref('organizations')
    ref.push(data)
  },
  async update(id, data) {
    const functions = firebase.functions()
    const updateOrganization = functions.httpsCallable('updateOrganization')
    const result = await updateOrganization({
      organizationId: id,
      organization: data,
    })
    return result
  },
  async updateLogo(organizationId, filename, data) {
    // Upload file
    const storageRef = firebase.storage().ref()
    const uploadTaskSnapshot = await storageRef
      .child(`organizations/${organizationId}/logo/${filename}`)
      .put(data)
    const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL()

    // Update realtime database
    const updates = {}
    updates[`organizations/${organizationId}/logo`] = filename
    await firebase.database().ref().update(updates)

    return downloadUrl
  },
  async deleteLogo(organizationId) {
    const updates = {}
    updates[`organizations/${organizationId}/logo`] = ''
    await firebase.database().ref().update(updates)
  },
}
